<template>
    <a-card 
        size="small" 
        :title="isMobile ? false : $t('wgr.about_me')"
        :class="isMobile && 'about_card_mobile'">
        <div 
            v-if="isAuthor || privateGroup" 
            class="row_info flex-items-center">
            <a-tag 
                v-if="isAuthor"
                class="mr-2"
                color="green">
                {{ $t('wgr.is_author') }}
            </a-tag>
            <a-tag
                v-if="privateGroup"
                color="red">
                {{ $t('wgr.closed') }}
            </a-tag>
        </div>
        <div class="user-bio row_info">
            <h6 class="font-semibold">{{ $t('wgr.description')}}</h6>
            <p class="break-words">
                {{ descLength }}
            </p>
            <div v-if="showDescBtn">
                <span 
                    class="desc_more" 
                    @click="showDesc = !showDesc">
                    {{ showDesc ? $t('wgr.hide') : $t('wgr.more') }}
                </span>
            </div>
        </div>

        <!-- Срок сдачи проекта -->
        <div v-if="requestData.dead_line !== null" class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.deadline_project") }}:</h6>
            <a-tag 
                color="purple" 
                class="mt-1">
                <span class="flex items-center">
                    <i class="fi fi-rr-clock-three mr-1"></i>
                    {{  $moment(requestData.dead_line).format("DD.MM.YYYY HH:mm") }}
                </span>
            </a-tag>
            <a-tag 
                v-if="requestData.control_dates"
                color="red" 
                class="mt-1">
                <span class="flex items-center">
                    Контроль дат включен
                </span>
            </a-tag>
        </div>

        <!-- Дата начала -->
        <div v-if="requestData.date_start_plan !== null" class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.date_start_plan") }}:</h6>
            {{  $moment(requestData.date_start_plan).format("DD.MM.YYYY HH:mm") }}
        </div>

        <!-- Тип клуба -->
        <div 
            v-if="requestData.workgroup_type !== undefined && requestData.workgroup_type.name !== ''" 
            class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.type") }}:</h6>
            <span>{{
                requestData.workgroup_type !== undefined
                    ? requestData.workgroup_type.name
                    : ""
            }}</span>
        </div>

        <!-- Кол-во участников -->
        <div 
            v-if="requestData.workgroup_members && requestData.workgroup_members.length" 
            class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.participants_count") }}:</h6>
            <span>{{ participantsText }}</span>
        </div>
        
        <!-- Кол-во задач
        <div v-if="requestData.tasks" class="row_info">
            <div class="row_info" :class="isMobile && 'flex'">
                <h6 class="font-semibold">{{ $t("wgr.count_task") }}:</h6>
                <span :class="isMobile && 'ml-2'">{{ requestData.tasks }}</span>
            </div>
            <div v-if="requestData.complete_tasks" :class="isMobile && 'flex'">
                <h6 class="font-semibold">{{ $t("wgr.tasks_complete") }}:</h6>
                <span :class="isMobile && 'ml-2'">{{ requestData.complete_tasks }}</span>
            </div>
    
            <div v-if="requestData.is_project">
                <a-progress
                    :stroke-color="{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }"
                    :percent="percent"/>
            </div>
        </div> -->

        <!-- Смета -->
        <div v-if="budgetStat" class="row_info">
            <h6 class="font-semibold">{{ $t('wgr.all_budget') }}:</h6>
            <span>{{ priceFormat(budgetStat.total_sum) }} <template v-if="budgetStat.currency">{{ budgetStat.currency.icon }}</template></span>
        </div>

        <!-- Оценка -->
        <div v-if="difficultyStat" class="row_info">
            <h6 class="font-semibold">{{ $t('wgr.all_difficulty') }}:</h6>
            <span>{{ priceFormat(difficultyStat.total_avg) }}</span>
        </div>

        <!-- Программа -->
        <div v-if="requestData.program" class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.program") }}:</h6>
            <span>{{ requestData.program.name }}</span>
        </div>

        <!-- Контрагент -->
        <div v-if="requestData.counterparty" class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.counterparty") }}:</h6>
            <span>{{ requestData.counterparty.name }}</span>
        </div>

        <!-- Объект калькуляции -->
        <div v-if="requestData.costing_object" class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.costing_object") }}:</h6>
            <span>{{ requestData.costing_object.name }}</span>
        </div>

        <div 
            v-if="requestData.organization" 
            class="row_info">
            <div class="mb-1 text-sm font-semibold">
                {{$t('wgr.organization')}}
            </div>
            <div 
                class="flex items-center cursor-pointer" >
                <!-- @click="openWorkgroup('viewGroup', task.organization)" -->
                <div>
                    <a-avatar 
                        :src="requestData.organization.logo" 
                        icon="team" 
                        :size="32" />
                </div>
                <span class="ml-2">{{ requestData.organization.name }}</span>
            </div>
        </div>


        <!-- Дата основания -->
        <div class="row_info">
            <h6 class="font-semibold">{{ $t("wgr.date_create") }}:</h6>
            <span>{{
                $moment(requestData.created_at).format("DD.MM.YYYY")
            }}</span>
        </div>
        <!-- Соц сети -->
        <div
            v-if="requestData.social_links.length > 0"
            class="social-links flex-column mt-4">
            <h6 class="font-semibold">{{ $t("wgr.social_links") }}:</h6>
            <div
                v-for="link in requestData.social_links"
                :key="link.id"
                class="flex">
                <a
                    class="mt-1"
                    target="_blank"
                    :href="link.social_link">{{ link.social_web_type.name }}</a>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapState } from "vuex"
import {declOfNum} from '../../utils'
import { priceFormatter } from '@/utils'
export default {
    name: "GroupsAndProjectAbout",
    props: {
        requestData: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        participantsText() {
            return this.requestData.workgroup_members.length + ' ' +
             declOfNum(this.requestData.workgroup_members.length, [this.$t('wgr.participant'), this.$t('wgr.participant2'), this.$t('wgr.participant3')])
        },
        descLength() {
            if(!this.showDesc && this.requestData?.description?.length > 175)
                return this.requestData.description.substr(0, 175) + '...'
            else
                return this.requestData.description
        },
        isAuthor() {
            if(this.user && this.user.id === this.requestData?.founder?.member?.id)
                return true
            else
                return null
        },
        privateGroup() {
            if(this.requestData?.public_or_private)
                return true
            else
                return false
        },
        percent(){
            return parseInt(((this.requestData.complete_tasks / this.requestData.tasks) * 100).toFixed(2))
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            showDesc: false,
            showDescBtn: false,
            budgetStat: null,
            difficultyStat: null
        }
    },
    created() {
        if(this.requestData?.description?.length > 175)
            this.showDescBtn = true

        this.getBudget()
        this.getDifficulty()
    },
    methods: {
        priceFormat(price) {
            return priceFormatter(String(price))
        },
        async getBudget() {
            if(!this.budgetStat) {
                try {
                    const { data } = await this.$http.get('/tasks/budget/aggregate/', {
                        params: {
                            obj: this.requestData.id
                        }
                    })
                    if(data) {
                        this.budgetStat = data
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        },
        async getDifficulty() {
            if(!this.difficultyStat) {
                try {
                    this.loading = true
                    const { data } = await this.$http.get('/tasks/difficulty/aggregate/', {
                        params: {
                            obj: this.requestData.id
                        }
                    })
                    if(data) {
                        this.difficultyStat = data
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            }
        }
    }
}
</script>

<style lang="scss">
.desc_more{
    color: var(--primaryColor);
    border-bottom: 1px dashed;
    cursor: pointer;
    font-size: 13px;
}

.about_card_mobile {
    .ant-card-body {
        padding: 0;
    }
}
.row_info {
    padding: 0.5rem 0;
}
.row_info + .row_info {
    border-top: 1px solid var(--borderColor);
}
</style>