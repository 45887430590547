<template>
    <div></div>
</template>

<script>
export default {
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>